<template>
  <div class="expandable-container" :key="player.Name">
    <a-row>
      <a-col :span="24">
        <h1>Basic Info</h1>
        <a-descriptions bordered size="small" class="basic-info">
          <a-descriptions-item label="Position">{{
            getPositionName(player)
          }}</a-descriptions-item>
          <a-descriptions-item label="Height">
            {{ getHeight(player) }}
          </a-descriptions-item>
          <a-descriptions-item label="Weight">{{
            getWeight(player)
          }}</a-descriptions-item>
          <a-descriptions-item label="Wingspan">
            {{ getWingspan(player) }}
          </a-descriptions-item>
          <a-descriptions-item label="Age">{{
            player.Age
          }}</a-descriptions-item>
          <a-descriptions-item label="Contract Length">
            {{ player["Contract Length"] }}
          </a-descriptions-item>
          <a-descriptions-item label="Status">
            {{ player.Status }}
          </a-descriptions-item>
          <a-descriptions-item label="Salary">
            {{
              globals.canBidFA.status
                ? "CONFIDENTIAL"
                : player["Contract Offer"].Cash
            }}
          </a-descriptions-item>
          <a-descriptions-item label="Loyalty">
            {{ player.Loyalty }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>
      <a-divider />
    </a-row>
    <div v-if="getRank() && notSLeagueTeam()">
      <h1>Notable Traits</h1>
      <div
        class="tidbits"
        :style="{ flexDirection: isMobile() ? 'column' : 'row' }"
      >
        <div
          class="tidbit"
          v-for="(item, index) in getTopThree(getRank())"
          :key="index"
        >
          {{ item }}
        </div>
      </div>
    </div>
    <a-row type="flex" :justify="noReports ? 'center' : 'space-between'">
      <a-col :xs="24" :md="7" class="player-info">
        <h1>Profile</h1>
        <img class="propic" :src="player.Image" />
        <div class="playArch">
          {{ getPlayerArchs(player) }}
        </div>
        <a-row v-if="shouldShowStatline()" type="flex" justify="center">
          <a-radio-group v-model:value="selectedTeam" button-style="solid">
            <a-radio-button
              v-for="team in getTeams()"
              :value="team"
              :key="team"
            >
              {{ team }}
            </a-radio-button>
          </a-radio-group>
        </a-row>
      </a-col>
      <a-col :xs="24" :md="16" v-if="!noReports">
        <h1>Analyst Reports</h1>

        <a-comment class="comment">
          <template #author>Zeke Lowe</template>
          <template #avatar>
            <a-avatar
              src="https://compote.slate.com/images/7bdff995-cf21-43db-bd3d-4d05c05de09b.jpg?width=840"
              alt="Zeke Lowe"
            />
          </template>
          <template #content>
            <p>
              {{ player.Zeke || "Scouting report in progress..." }}
            </p>
          </template>
        </a-comment>
        <a-comment class="comment">
          <template #author><a>Devin O'Connor</a></template>
          <template #avatar>
            <a-avatar
              src="https://secure.gravatar.com/avatar/3c38f59c8d8d28b5b523d14ae77c581c?rating=PG&size=1000&border=&default=https%3A%2F%2Fs.ltrbxd.com%2Fstatic%2Fimg%2Favatar1000.20abed80.png"
              alt="Kevin O'Connor"
            />
          </template>
          <template #content>
            <p>
              {{ player.Devin || "Scouting report in progress..." }}
            </p>
          </template>
        </a-comment>
        <a-comment class="comment">
          <template #author><a>Will Simmons</a></template>
          <template #avatar>
            <a-avatar
              src="https://cdn.vox-cdn.com/thumbor/7okcYfnGeYtA2KhRoPuJoVAs3v0=/0x483:2976x2747/1200x800/filters:focal(1179x1171:1655x1647)/cdn.vox-cdn.com/uploads/chorus_image/image/53071043/bill_simmons.0.jpg"
              alt="Will Simmons"
            />
          </template>
          <template #content>
            <p>
              {{ player.Will || "Scouting report in progress..." }}
            </p>
          </template>
        </a-comment>
      </a-col>
    </a-row>
    <a-row type="flex" justify="center">
      <div
        class="badges"
        :key="content"
        v-for="content in getPlayTypes(player)"
      >
        <div class="playType">
          {{ content?.label }}
        </div>
      </div>
    </a-row>
    <div v-if="shouldShowStatline()">
      <a-divider />
      <h1>
        Statline
      </h1>
      <div class="scroll-container">
        <Statline
          :data="[
            {
              ...getStats().Regular,
              ...getStats()['Player Info']
            }
          ]"
          :labels="statLabels"
          :digits="2"
        />
      </div>
    </div>
    <div v-if="shouldShowStatline()">
      <a-divider />
      <h1>
        Game Log
      </h1>
      <div class="scroll-container">
        <Statline
          :data="getBoxScores()"
          :labels="getBoxScoreLabels()"
          :digits="0"
        />
      </div>
    </div>
    <p v-if="!!player['NBA comparison']">
      <a-divider />
      <strong>NBA COMP</strong>:
      <a v-on:click="getHighlightLink(player)">{{
        player["NBA comparison"]
      }}</a>
    </p>
    <a-divider v-if="playerAttributes" />
    <h1 v-if="playerAttributes">
      Attributes
    </h1>
    <a-row type="flex" justify="space-between" v-if="playerAttributes">
      <a-col :xs="24" :md="8">
        <div class="attr-container">
          <h2 class="attr-title">
            <span
              class="attr-value"
              v-bind:style="{
                backgroundColor: valColor(
                  getMeanRating(playerAttributes.Shooting)
                )
              }"
            >
              {{ getMeanRating(playerAttributes.Shooting) }}
            </span>
            Shooting
          </h2>

          <div
            class="attr-item"
            :key="name"
            v-for="(content, name, index) in playerAttributes.Shooting"
          >
            <span
              class="attr-value"
              v-bind:style="{
                backgroundColor: valColor(getRating(content.value))
              }"
            >
              {{ getRating(content.value) }}
            </span>
            <span class="attr-name">
              {{ content.name }}
            </span>
          </div>
        </div>
        <div class="attr-container">
          <h2 class="attr-title">
            <span
              class="attr-value"
              v-bind:style="{
                backgroundColor: valColor(
                  getMeanRating(playerAttributes.Athleticism)
                )
              }"
            >
              {{ getMeanRating(playerAttributes.Athleticism) }}
            </span>
            Athleticism
          </h2>

          <div
            class="attr-item"
            :key="name"
            v-for="(content, name, index) in playerAttributes.Athleticism"
          >
            <span
              class="attr-value"
              v-bind:style="{
                backgroundColor: valColor(getRating(content.value))
              }"
            >
              {{ getRating(content.value) }}
            </span>
            <span class="attr-name">
              {{ content.name }}
            </span>
          </div>
        </div>
        <div class="attr-container">
          <h2 class="attr-title">
            <span
              class="attr-value"
              v-bind:style="{
                backgroundColor: valColor(
                  getMeanRating(playerAttributes.Conditioning)
                )
              }"
            >
              {{ getMeanRating(playerAttributes.Conditioning) }}
            </span>
            Conditioning
          </h2>

          <div
            class="attr-item"
            :key="name"
            v-for="(content, name, index) in playerAttributes.Conditioning"
          >
            <span
              class="attr-value"
              v-bind:style="{
                backgroundColor: valColor(getRating(content.value))
              }"
            >
              {{ getRating(content.value) }}
            </span>
            <span class="attr-name">
              {{ content.name }}
            </span>
          </div>
        </div>
      </a-col>
      <a-col :xs="24" :md="8">
        <div class="attr-container">
          <h2 class="attr-title">
            <span
              class="attr-value"
              v-bind:style="{
                backgroundColor: valColor(
                  getMeanRating(playerAttributes.Finishing)
                )
              }"
            >
              {{ getMeanRating(playerAttributes.Finishing) }}
            </span>
            Finishing
          </h2>

          <div
            class="attr-item"
            :key="name"
            v-for="(content, name, index) in playerAttributes.Finishing"
          >
            <span
              class="attr-value"
              v-bind:style="{
                backgroundColor: valColor(getRating(content.value))
              }"
            >
              {{ getRating(content.value) }}
            </span>
            <span class="attr-name">
              {{ content.name }}
            </span>
          </div>
        </div>
        <div class="attr-container">
          <h2 class="attr-title">
            <span
              class="attr-value"
              v-bind:style="{
                backgroundColor: valColor(
                  getMeanRating(playerAttributes.Playmaking)
                )
              }"
            >
              {{ getMeanRating(playerAttributes.Playmaking) }}
            </span>
            Playmaking
          </h2>

          <div
            class="attr-item"
            :key="name"
            v-for="(content, name, index) in playerAttributes.Playmaking"
          >
            <span
              class="attr-value"
              v-bind:style="{
                backgroundColor: valColor(getRating(content.value))
              }"
            >
              {{ getRating(content.value) }}
            </span>
            <span class="attr-name">
              {{ content.name }}
            </span>
          </div>
        </div>
        <div class="attr-container">
          <h2 class="attr-title">
            <span
              class="attr-value"
              v-bind:style="{
                backgroundColor: valColor(
                  getMeanRating(playerAttributes['Post Game'])
                )
              }"
            >
              {{ getMeanRating(playerAttributes["Post Game"]) }}
            </span>
            Post Game
          </h2>

          <div
            class="attr-item"
            :key="name"
            v-for="(content, name, index) in playerAttributes['Post Game']"
          >
            <span
              class="attr-value"
              v-bind:style="{
                backgroundColor: valColor(getRating(content.value))
              }"
            >
              {{ getRating(content.value) }}
            </span>
            <span class="attr-name">
              {{ content.name }}
            </span>
          </div>
        </div>
      </a-col>
      <a-col :xs="24" :md="8">
        <div class="attr-container">
          <h2 class="attr-title">
            <span
              class="attr-value"
              v-bind:style="{
                backgroundColor: valColor(
                  getMeanRating(playerAttributes.Defense)
                )
              }"
            >
              {{ getMeanRating(playerAttributes.Defense) }}
            </span>
            Defense
          </h2>

          <div
            class="attr-item"
            :key="name"
            v-for="(content, name, index) in playerAttributes.Defense"
          >
            <span
              class="attr-value"
              v-bind:style="{
                backgroundColor: valColor(getRating(content.value))
              }"
            >
              {{ getRating(content.value) }}
            </span>
            <span class="attr-name">
              {{ content.name }}
            </span>
          </div>
        </div>
        <div class="attr-container">
          <h2 class="attr-title">
            <span
              class="attr-value"
              v-bind:style="{
                backgroundColor: valColor(
                  getMeanRating(playerAttributes.Rebounding)
                )
              }"
            >
              {{ getMeanRating(playerAttributes.Rebounding) }}
            </span>
            Rebounding
          </h2>

          <div
            class="attr-item"
            :key="name"
            v-for="(content, name, index) in playerAttributes.Rebounding"
          >
            <span
              class="attr-value"
              v-bind:style="{
                backgroundColor: valColor(getRating(content.value))
              }"
            >
              {{ getRating(content.value) }}
            </span>
            <span class="attr-name">
              {{ content.name }}
            </span>
          </div>
        </div>
        <div class="attr-container">
          <h2 class="attr-title">
            <span
              class="attr-value"
              v-bind:style="{
                backgroundColor: valColor(
                  getMeanRating(playerAttributes.Mental)
                )
              }"
            >
              {{ getMeanRating(playerAttributes.Mental) }}
            </span>
            Mental
          </h2>

          <div
            class="attr-item"
            :key="name"
            v-for="(content, name, index) in playerAttributes.Mental"
          >
            <span
              class="attr-value"
              v-bind:style="{
                backgroundColor: valColor(getRating(content.value))
              }"
            >
              {{ getRating(content.value) }}
            </span>
            <span class="attr-name">
              {{ content.name }}
            </span>
          </div>
        </div>
      </a-col>
    </a-row>
    <a-divider v-if="playerBadges" />
    <h1 v-if="playerBadges">
      Badges
    </h1>
    <h4 v-if="playerBadges">
      Hover over badges for more info
    </h4>
    <a-row v-if="playerBadges">
      <a-tabs v-model:activeKey="selectedBadgeType">
        <a-tab-pane key="All" tab="All">
          <div class="badges-container">
            <div
              class="badges"
              :key="name"
              v-for="(content, name, index) in getOrderedBadgeKeys(
                getFlatCollection(playerBadges)
              )"
            >
              <a-tooltip
                placement="topLeft"
                :title="content.desc"
                arrow-point-at-center
              >
                <div
                  class="badge"
                  v-bind:style="{
                    backgroundColor: badgeColor(content.value)
                  }"
                  v-if="content.value != '0'"
                >
                  {{ content.name }}
                </div>
              </a-tooltip>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="Finishing" tab="Finishing"
          ><div class="badges-container">
            <div
              class="badges"
              :key="name"
              v-for="(content, name, index) in getOrderedBadgeKeys(
                playerBadges.Finishing
              )"
            >
              <a-tooltip
                placement="topLeft"
                :title="content.desc"
                arrow-point-at-center
              >
                <div
                  class="badge"
                  v-bind:style="{
                    backgroundColor: badgeColor(content.value)
                  }"
                  v-if="content.value != '0'"
                >
                  {{ content.name }}
                </div>
              </a-tooltip>
            </div>
          </div></a-tab-pane
        >
        <a-tab-pane key="Shooting" tab="Shooting"
          ><div class="badges-container">
            <div
              class="badges"
              :key="name"
              v-for="(content, name, index) in getOrderedBadgeKeys(
                playerBadges.Shooting
              )"
            >
              <a-tooltip
                placement="topLeft"
                :title="content.desc"
                arrow-point-at-center
              >
                <div
                  class="badge"
                  v-bind:style="{
                    backgroundColor: badgeColor(content.value)
                  }"
                  v-if="content.value != '0'"
                >
                  {{ content.name }}
                </div>
              </a-tooltip>
            </div>
          </div></a-tab-pane
        >
        <a-tab-pane key="Playmaking" tab="Playmaking"
          ><div class="badges-container">
            <div
              class="badges"
              :key="name"
              v-for="(content, name, index) in getOrderedBadgeKeys(
                playerBadges.Playmaking
              )"
            >
              <a-tooltip
                placement="topLeft"
                :title="content.desc"
                arrow-point-at-center
              >
                <div
                  class="badge"
                  v-bind:style="{
                    backgroundColor: badgeColor(content.value)
                  }"
                  v-if="content.value != '0'"
                >
                  {{ content.name }}
                </div>
              </a-tooltip>
            </div>
          </div></a-tab-pane
        >
        <a-tab-pane key="Defense/Rebounding" tab="Defense/Rebounding"
          ><div class="badges-container">
            <div
              class="badges"
              :key="name"
              v-for="(content, name, index) in getOrderedBadgeKeys(
                playerBadges['Defense/Rebounding']
              )"
            >
              <a-tooltip
                placement="topLeft"
                :title="content.desc"
                arrow-point-at-center
              >
                <div
                  class="badge"
                  v-bind:style="{
                    backgroundColor: badgeColor(content.value)
                  }"
                  v-if="content.value != '0'"
                >
                  {{ content.name }}
                </div>
              </a-tooltip>
            </div>
          </div></a-tab-pane
        >
        <a-tab-pane key="Personality" tab="Personality"
          ><div class="badges-container">
            <div
              class="badges"
              :key="name"
              v-for="(content, name, index) in getOrderedBadgeKeys(
                playerBadges.Personality
              )"
            >
              <a-tooltip
                placement="topLeft"
                :title="content.desc"
                arrow-point-at-center
              >
                <div
                  class="badge"
                  v-bind:style="{
                    backgroundColor: badgeColor(content.value)
                  }"
                  v-if="content.value != '0'"
                >
                  {{ content.name }}
                </div>
              </a-tooltip>
            </div>
          </div></a-tab-pane
        >
      </a-tabs>
    </a-row>
    <a-divider v-if="playerTendencies" />
    <h1 v-if="playerTendencies">
      Tendencies
    </h1>
    <a-row type="flex" justify="space-between">
      <a-col :xs="24" :md="6">
        <div class="attr-container">
          <h2 class="attr-title">
            Shooting
          </h2>
          <div
            class="attr-item"
            :key="index"
            v-for="(content, index) in getTendencyObjects().slice(0, 31)"
          >
            <span
              class="attr-value"
              v-bind:style="{
                backgroundColor: valColor(content.value)
              }"
            >
              {{ content.value }}
            </span>
            <span class="attr-name">
              {{ formatTendency(content.name) }}
            </span>
          </div>
        </div>
      </a-col>
      <a-col :xs="24" :md="6">
        <div class="attr-container">
          <h2 class="attr-title">
            Finishing
          </h2>
          <div
            class="attr-item"
            :key="index"
            v-for="(content, index) in getTendencyObjects().slice(31, 42)"
          >
            <span
              class="attr-value"
              v-bind:style="{
                backgroundColor: valColor(content.value)
              }"
            >
              {{ content.value }}
            </span>
            <span class="attr-name">
              {{ formatTendency(content.name) }}
            </span>
          </div>
        </div>
        <div class="attr-container">
          <h2 class="attr-title">
            Triple Threat Setup
          </h2>
          <div
            class="attr-item"
            :key="index"
            v-for="(content, index) in getTendencyObjects().slice(42, 49)"
          >
            <span
              class="attr-value"
              v-bind:style="{
                backgroundColor: valColor(content.value)
              }"
            >
              {{ content.value }}
            </span>
            <span class="attr-name">
              {{ formatTendency(content.name) }}
            </span>
          </div>
        </div>
        <div class="attr-container">
          <h2 class="attr-title">
            Playmaking
          </h2>
          <div
            class="attr-item"
            :key="index"
            v-for="(content, index) in getTendencyObjects().slice(63, 66)"
          >
            <span
              class="attr-value"
              v-bind:style="{
                backgroundColor: valColor(content.value)
              }"
            >
              {{ content.value }}
            </span>
            <span class="attr-name">
              {{ formatTendency(content.name) }}
            </span>
          </div>
        </div>
      </a-col>
      <a-col :xs="24" :md="6">
        <div class="attr-container">
          <h2 class="attr-title">
            Driving
          </h2>
          <div
            class="attr-item"
            :key="index"
            v-for="(content, index) in getTendencyObjects().slice(49, 63)"
          >
            <span
              class="attr-value"
              v-bind:style="{
                backgroundColor: valColor(content.value)
              }"
            >
              {{ content.value }}
            </span>
            <span class="attr-name">
              {{ formatTendency(content.name) }}
            </span>
          </div>
        </div>
        <div class="attr-container">
          <h2 class="attr-title">
            Defense
          </h2>
          <div
            class="attr-item"
            :key="index"
            v-for="(content, index) in getTendencyObjects().slice(92, 100)"
          >
            <span
              class="attr-value"
              v-bind:style="{
                backgroundColor: valColor(content.value)
              }"
            >
              {{ content.value }}
            </span>
            <span class="attr-name">
              {{ formatTendency(content.name) }}
            </span>
          </div>
        </div>
      </a-col>
      <a-col :xs="24" :md="6">
        <div class="attr-container">
          <h2 class="attr-title">
            Post Moves
          </h2>
          <div
            class="attr-item"
            :key="index"
            v-for="(content, index) in getTendencyObjects().slice(66, 83)"
          >
            <span
              class="attr-value"
              v-bind:style="{
                backgroundColor: valColor(content.value)
              }"
            >
              {{ content.value }}
            </span>
            <span class="attr-name">
              {{ formatTendency(content.name) }}
            </span>
          </div>
        </div>

        <div class="attr-container">
          <h2 class="attr-title">
            Mental
          </h2>
          <div
            class="attr-item"
            :key="index"
            v-for="(content, index) in getTendencyObjects().slice(83, 92)"
          >
            <span
              class="attr-value"
              v-bind:style="{
                backgroundColor: valColor(content.value)
              }"
            >
              {{ content.value }}
            </span>
            <span class="attr-name">
              {{ formatTendency(content.name) }}
            </span>
          </div>
        </div>
      </a-col>
    </a-row>
    <a-divider v-if="playerHotZones" />
    <h1 v-if="playerHotZones">
      Hotzones
    </h1>
    <a-row v-if="playerHotZones" justify="center">
      <div class="hotzone">
        <a-image
          class="hotzone-image"
          :width="'100%'"
          src="https://i.imgur.com/28XbiwJ.png"
        />
        <div
          v-for="(content, name, index) in playerHotZones"
          :key="index"
          v-bind:style="{
            position: 'absolute',
            top: content.y,
            left: content.x,
            opacity: content.value == 1 ? 0 : 1
          }"
        >
          <div
            class="dot"
            v-bind:style="{
              backgroundColor: hotzoneColor(content.value)
            }"
          />
        </div>
      </div>
    </a-row>
    <a-divider />
    <h1>
      Updates
    </h1>
    <a-row justify="center">
      <div class="attr-container">
        <div
          class="attr-item"
          v-for="(content, name, index) in getChanges()"
          :key="index"
        >
          <span
            class="attr-value"
            v-bind:style="{
              backgroundColor: changeColor(content)
            }"
          >
            {{ content }}
          </span>
          <span class="attr-name">
            {{ formatChange(name) }}
          </span>
        </div>
      </div>

      <!-- <a-col :xs="24" :md="18"> -->
      <!-- <a-comment
          :key="index"
          v-for="(update, index) in getPendingBoosts()"
          class="comment"
        >
          <template #author>Edrian Rojnarowski on {{ update.Date }} </template>
          <template #avatar>
            <a-avatar
              src="https://pbs.twimg.com/profile_images/1294374587363360768/sWuWhPuN_400x400.png"
              alt="Roj"
            />
          </template>
          <template #content>
            <p>
              {{ update.Tweet }}
            </p>
          </template>
        </a-comment> -->
      <!-- </a-col> -->
    </a-row>
    <a-divider />
    <a-row
      :span="24"
      justify="center"
      @click="this.clearExpanded()"
      v-if="this.clearExpanded"
    >
      <div class="collapse">
        <UpOutlined />
      </div>
    </a-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";
import searchYoutube from "youtube-api-v3-search";
import { labels } from "../models/labels";
import Statline from "./Statline";
import { UpOutlined } from "@ant-design/icons-vue";
import {
  getPositionName,
  getHeight,
  getPlayTypes,
  getWeight,
  getWingspan,
  getPlayerArchs
} from "../utils/playerHelper";
import { boxScoreLabels } from "../models/labels";
import { nameToInitial } from "../utils/convertHelper";

export default {
  name: "PlayerDetails",
  props: [
    "player",
    "playerCount",
    "clearExpanded",
    "showBasicInfo",
    "noReports"
  ],
  setup() {
    return {
      getPositionName,
      getHeight,
      getPlayTypes,
      getWeight,
      getWingspan,
      boxScoreLabels,
      getPlayerArchs
    };
  },
  data() {
    return {
      selectedBadgeType: "All",
      selectedTeam: _.get(this.player, ["Stats", this.player.Team])
        ? this.player.Team
        : _.keys(_.get(this.player, ["Stats"]))[0],
      playerAttributes: _.get(this.player.Data, ["ATTRIBUTES"], false),
      playerBadges: _.get(this.player.Data, ["BADGES"], false),
      playerHotZones: _.get(this.player.Data, ["HOTZONE"], false),
      playerTendencies: _.get(this.player.Data, ["TENDENCIES"], false),
      charTypes: {
        standard: ["Points", "Rebounds", "Assists", "Steals", "Blocks"],
        efficiency: ["FG%", "3PT%", "FT%", "TS%", "eFG%"],
        advanced: ["PER", "USG%", "GmSc", "Rtg Diff", "WS"]
      }
    };
  },
  computed: {
    ...mapState(["rawGameStats", "globals"]),
    chartWidth() {
      return this.isMobile()
        ? window.outerWidth * 0.9
        : window.innerWidth * 0.3;
    },
    statLabels() {
      return labels;
    }
  },
  created() {},
  methods: {
    getTeams() {
      return _.keys(this.player.Stats);
    },
    getRank() {
      return _.get(
        this.player,
        ["Rank", this.selectedTeam],
        _.get(this.player, ["Rank", this.player.Team])
      );
    },
    getStats() {
      return _.get(this.player, ["Stats", this.selectedTeam], false);
    },
    shouldShowStatline() {
      return _.get(this.player, ["Stats"], false);
    },
    notSLeagueTeam() {
      const alternativeTeams = ["Suns", "Jazz", "Kings", "Hornets"];
      return !alternativeTeams.includes(this.selectedTeam);
    },
    valColor(val) {
      const rtng = parseInt(val);
      if (rtng >= 90) {
        return "#0a0";
      } else if (rtng >= 80) {
        return "#070";
      } else if (rtng >= 70) {
        return "#c90";
      } else if (rtng >= 60) {
        return "#d40";
      } else {
        return "#900";
      }
    },
    changeColor(val) {
      const rtng = parseInt(val);
      if (rtng >= 0) {
        return "#0a0";
      } else {
        return "#900";
      }
    },
    badgeColor(val) {
      const rtng = parseInt(val);
      if (rtng == 5) {
        return "#B53737";
      } else if (rtng == 4) {
        return "#BA56D8";
      } else if (rtng == 3) {
        return "#FFD700";
      } else if (rtng == 2) {
        return "#c0c0c0";
      } else if (rtng == 1) {
        return "#b08d57";
      } else {
        return "#900";
      }
    },
    hotzoneColor(val) {
      const rtng = parseInt(val);

      if (rtng == 2) {
        return "#FF3131";
      } else if (rtng == 0) {
        return "#0096FF";
      } else {
        return "#900";
      }
    },
    getFlatCollection(col) {
      return _.values(col).reduce(function(result, current) {
        return Object.assign(result, current);
      }, {});
    },
    getRating(val) {
      return Math.round(parseInt(val) / 3 + 25);
    },
    getMeanRating(cat) {
      const values = Object.values(cat).map(cont => this.getRating(cont.value));

      return Math.round(_.mean(values));
    },
    getOrderedBadgeKeys(badges) {
      return _.orderBy(badges, "value", "desc");
    },
    shouldDisplayStats() {
      if (this.player.Team === "FA") return true;
      return this.getTeams().length > 1 ? true : false;
    },
    getTopThree(rankList) {
      const rankListArray = [];
      const excludedStats = ["Games Played"];
      for (let rankItem in rankList) {
        rankListArray.push([rankItem, rankList[rankItem]]);
      }
      rankListArray.sort((a, b) => a[1] - b[1]);

      return rankListArray
        .filter(rankItem => !_.includes(excludedStats, rankItem[0]))
        .slice(0, 3)
        .map(rankItem => {
          return `${this.ordinal_suffix_of(rankItem[1])} in ${rankItem[0]}`;
        });
    },
    ordinal_suffix_of(i) {
      const j = i % 10,
        k = i % 100;
      if (j == 1 && k != 11) {
        return i + "st";
      }
      if (j == 2 && k != 12) {
        return i + "nd";
      }
      if (j == 3 && k != 13) {
        return i + "rd";
      }
      return i + "th";
    },

    getChartOptions(player, cats, title) {
      const playerCount = this.playerCount;
      return {
        chartOptions: {
          chart: {
            id: player,
            toolbar: {
              show: false
            }
          },
          title: {
            text: title
          },
          tooltip: {
            enabled: true,
            x: {
              show: false
            },
            y: {
              formatter: function(
                value,
                { series, seriesIndex, dataPointIndex, w }
              ) {
                return playerCount - value;
              }
            },
            style: {
              fontSize: "20px"
            }
          },
          xaxis: {
            categories: cats.map(cat => labels[cat] || cat)
          },
          yaxis: {
            show: false,
            max: playerCount
          },
          fill: {
            colors: ["#2FDAA4", "#2FDAA4"]
          },
          stroke: {
            colors: ["#1DA57A", "#1DA57A"]
          },
          markers: {
            colors: ["#1DA57A", "#1DA57A"]
          }
        },
        series: [
          {
            name: "rank",
            data: cats.map(cat => this.playerCount - player[cat])
          }
        ]
      };
    },
    async getHighlightLink(player) {
      let result = await searchYoutube(process.env.VUE_APP_YOUTUBE_API_KEY, {
        q: `${player["NBA comparison"]} NBA Highlights`,
        type: "video"
      });
      window.open(
        `https://www.youtube.com/watch?v=${_.get(result, [
          "items",
          0,
          "id",
          "videoId"
        ])}`
      );
    },
    isMobile() {
      return window.outerWidth < 500;
    },
    getPendingBoosts() {
      return this.player.Updates.filter(
        boost => boost["Done?"] !== "yes" && !(boost.Event === "budget")
      );
    },
    getChanges() {
      return this.player.Changes;
    },
    formatTendency(name) {
      const formattedName = name.toLowerCase().replaceAll("_", " ");

      return formattedName
        .substring(0, formattedName.lastIndexOf(" "))
        .split(" ")
        .map(s => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
    },
    formatChange(name) {
      const formattedName = name.toLowerCase().replaceAll("_", " ");

      return formattedName
        .split(" ")
        .map(s => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
    },
    getTendencyObjects() {
      const playerTenArr = [];
      _.keys(this.playerTendencies).forEach(pt => {
        playerTenArr.push({
          name: pt,
          value: this.playerTendencies[pt]
        });
      });
      return playerTenArr;
    },
    getBoxScores() {
      return this.rawGameStats
        .filter(rawGame => {
          return rawGame["Player"] === nameToInitial(this.player.Name);
        })
        .reverse();
    },
    getBoxScoreLabels() {
      return _.omit(boxScoreLabels, "Player");
    }
  },
  components: {
    Statline,
    UpOutlined
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.custom-row {
  cursor: pointer;
}
</style>

<style scoped lang="scss">
.comment {
  background-color: white;
  padding: 0px 20px;
  border-radius: 5px;
  margin: 20px 0px;
  transition: 0.2s;
  border: 1px solid lightgray;
  font-size: 26px;
  width: 100%;
}

.tidbits {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-weight: bold;
  padding: 0, 10px;
  font-size: 20px;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  margin: 20px 0px;
  transition: 0.2s;
  border: 1px solid lightgray;
}

.charts-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  max-width: 1400px;
}

.expandable-container {
  // margin-left: -60px;
  // padding: 0px 60px;
  // max-width: 1440px;
}

.propic {
  border-radius: 5px;
  max-width: 100%;
  margin-bottom: 20px;
  box-shadow: 0 0 20px 20px #001529 inset;
  background-image: url("https://external-preview.redd.it/DcosmF7ItUt7rP6QIQbYn9MC6H59uV3ePnshLhUa0NQ.png?auto=webp&s=70bba5e7e0e9aa62881193a3a60159f42fb6eeaa");
}

.attr-container {
  background-color: white;
  padding: 10px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  transition: 0.2s;
  border: 1px solid lightgray;
}

.attr-value {
  font-weight: bold;
  font-size: 15px;
  width: 30px;
  height: 30px;
  margin: 5px;
  color: white;
  text-align: center;
  justify-content: center;
  display: inline-flex;
  align-items: center;
  border-radius: 25px;
}

.attr-name {
  font-weight: bold;
  height: 30px;
  margin: 5px;
  text-align: center;
  display: inline-flex;
  align-items: center;
}

.attr-item {
  display: flex;
  margin: 5px;
}

.attr-title {
  border-bottom: 1px solid lightgray;
  padding-bottom: 10px;
}

.badges-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.badge {
  cursor: pointer;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  font-weight: bold;
  color: white;
  border-color: #9ecaed;
  box-shadow: 0 0px 5px #9ecaed;
}

.playType {
  cursor: pointer;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  font-weight: bold;
  background-color: #2c3e50;
  color: white;
  border-color: #9ecaed;
  box-shadow: 0 0px 5px #9ecaed;
}

.playArch {
  cursor: pointer;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  font-weight: bold;
  background-color: white;
  color: black;
  border-color: #9ecaed;
  position: absolute;
  top: 45px;
}

.hotzone {
  position: relative;
  border-radius: 5px;
  border-color: #9ecaed;
  max-width: 600px;
  width: 100%;

  @media only screen and (max-width: 600px) {
    max-width: calc(100vw - 36px);
  }
}

.dot {
  position: absolute;
  height: 30px;
  width: 30px;
  left: -15px;
  top: -15px;
  border-radius: 50%;
  display: inline-block;
}

.collapse {
  width: 100%;
  text-align: center;
  padding: 5px;
  cursor: pointer;
}

.basic-info {
  border-radius: 5px;
  transition: 0.2s;
  border: 1px solid lightgray;
  background-color: white;
}

.tendencies {
  background-color: white;
  padding: 10px;
  margin: 10px;
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  transition: 0.2s;
  border: 1px solid lightgray;
}

.scroll-container {
  width: "100%";
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}
</style>
