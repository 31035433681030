import _ from "lodash";

export const rojUpdate = {
  Date: "",
  Event: "",
  Tweet: "",
  "Done?": ""
};

export const stats = {
  Regular: {
    Points: "",
    Rebounds: "",
    "Defensive Rebounds": "",
    "Offensive Rebounds": "",
    Assists: "",
    Steals: "",
    Blocks: "",
    Turnovers: "",
    "FG%": "",
    "Field Goals Made": "",
    "Field Goals Taken": "",
    "3PT%": "",
    "3PT Taken": "",
    "3PT Made": "",
    FTs: "",
    "FT%": "",
    "FT Attempted": "",
    Dunks: "",
    Fouls: "",
    "+/-": "",
    "Points Responsible For": ""
  },
  Previous: {
    Points: "",
    Rebounds: "",
    "Defensive Rebounds": "",
    "Offensive Rebounds": "",
    Assists: "",
    Steals: "",
    Blocks: "",
    Turnovers: "",
    "FG%": "",
    "Field Goals Made": "",
    "Field Goals Taken": "",
    "3PT%": "",
    "3PT Taken": "",
    "3PT Made": "",
    FTs: "",
    "FT%": "",
    "FT Attempted": "",
    Dunks: "",
    Fouls: "",
    "+/-": "",
    "Points Responsible For": ""
  },
  Advanced: {
    PER: "",
    GmSc: "",
    "Floor%": "",
    "USG%": "",
    "AST%": "",
    "BLK%": "",
    "TRB%": "",
    "DRB%": "",
    "ORB%": "",
    "STL%": "",
    "TOV%": "",
    "TS%": "",
    "eFG%": "",
    ORtg: "",
    DRtg: "",
    "Rtg Diff": "",
    OWS: "",
    "OWS/48": "",
    DWS: "",
    "DWS/48": "",
    WS: "",
    "WS/48": ""
  },
  "Per 36": {
    "Points/36": "",
    "Rebounds/36": "",
    "Offensive Rebounds/36": "",
    "Assists/36": "",
    "Steals/36": "",
    "Blocks/36": "",
    "Turnovers/36": "",
    "Field Goals Made/36": "",
    "Field Goals Taken/36": "",
    "3PT Taken/36": "",
    "3PT Made/36": "",
    "FTs/36": "",
    "FT Attempted/36": "",
    "Dunks/36": "",
    "Fouls/36": "",
    "Points Responsible For/36": ""
  }
};

export const ranks = {
  "3PT%": "",
  "3PT Taken": "",
  "3PT Made": "",
  "+/-": "",
  Assists: "",
  Blocks: "",
  "Defensive Rebounds": "",
  Dunks: "",
  "FG%": "",
  "FT%": "",
  "FT Attempted": "",
  FTs: "",
  "Field Goals Made": "",
  "Field Goals Taken": "",
  Fouls: "",
  "Games Played": "",
  Minutes: "",
  "Offensive Rebounds": "",
  Points: "",
  "Points Responsible For": "",
  Rebounds: "",
  Steals: "",
  Turnovers: "",
  "AST%": "",
  "BLK%": "",
  "DRB%": "",
  DRtg: "",
  DWS: "",
  "DWS/48": "",
  "Floor%": "",
  GmSc: "",
  "ORB%": "",
  ORtg: "",
  OWS: "",
  "OWS/48": "",
  PER: "",
  "Rtg Diff": "",
  "STL%": "",
  "TOV%": "",
  "TRB%": "",
  "TS%": "",
  "USG%": "",
  WS: "",
  "WS/48": "",
  "eFG%": "",
  "3PT Taken/36": "",
  "3PT Made/36": "",
  "Assists/36": "",
  "Blocks/36": "",
  "Dunks/36": "",
  "Offensive Rebounds/36": "",
  "Points/36": "",
  "Rebounds/36": "",
  "Steals/36": "",
  "Turnovers/36": "",
  "Field Goals Made/36": "",
  "Field Goals Taken/36": "",
  "FTs/36": "",
  "FT Attempted/36": "",
  "Fouls/36": "",
  "Points Responsible For/36": ""
};

export const attributes = {
  Shooting: {
    SHOT_CLOSE: {
      name: "Close Shot",
      value: 0
    },
    "MID-RANGE_SHOT": {
      name: "Mid-Range Shot",
      value: 0
    },
    "3PT_SHOT": {
      name: "Three-Point Shot",
      value: 0
    },
    FREE_THROW: {
      name: "Free Throw",
      value: 0
    },
    SHOT_IQ: {
      name: "Shot IQ",
      value: 0
    }
  },
  Finishing: {
    DRIVING_LAYUP: {
      name: "Layup",
      value: 0
    },
    STANDING_DUNK: {
      name: "Standing Dunk",
      value: 0
    },
    DRIVING_DUNK: {
      name: "Driving Dunk",
      value: 0
    },
    DRAW_FOUL: {
      name: "Draw Foul",
      value: 0
    },
    HANDS: {
      name: "Hands",
      value: 0
    },
    BALL_CONTROL: {
      name: "Ball Handle",
      value: 0
    }
  },
  "Post Game": {
    POST_MOVES: {
      name: "Post Moves",
      value: 0
    },
    POST_FADEAWAY: {
      name: "Post Fadeaway",
      value: 0
    },
    POST_HOOK: {
      name: "Post Hook",
      value: 0
    }
  },
  Defense: {
    INTERIOR_DEFENSE: {
      name: "Interior Defense",
      value: 0
    },
    PERIMETER_DEFENSE: {
      name: "Perimeter Defense",
      value: 0
    },
    STEAL: {
      name: "Steal",
      value: 0
    },
    BLOCK: {
      name: "Block",
      value: 0
    },
    PASS_PERCEPTION: {
      name: "Pass Perception",
      value: 0
    },
    HELP_DEFENSIVE_IQ: {
      name: "Help Defense IQ",
      value: 0
    }
  },
  Athleticism: {
    SPEED: {
      name: "Speed",
      value: 0
    },
    ACCELERATION: {
      name: "Acceleration",
      value: 0
    },
    SPEED_WITH_BALL: {
      name: "Speed with Ball",
      value: 0
    },
    LATERAL_QUICKNESS: {
      name: "Lateral Quickness",
      value: 0
    },
    VERTICAL: {
      name: "Vertical",
      value: 0
    },
    STRENGTH: {
      name: "Strength",
      value: 0
    }
  },
  Playmaking: {
    PASSING_ACCURACY: {
      name: "Pass Accuracy",
      value: 0
    },
    PASSING_IQ: {
      name: "Pass IQ",
      value: 0
    },
    PASSING_VISION: {
      name: "Pass Vision",
      value: 0
    }
  },
  Rebounding: {
    OFFENSIVE_REBOUND: {
      name: "Offensive Rebounding",
      value: 0
    },
    DEFENSIVE_REBOUND: {
      name: "Defensive Rebounding",
      value: 0
    }
  },
  Mental: {
    HUSTLE: {
      name: "Hustle",
      value: 0
    },
    OFFENSIVE_CONSISTENCY: {
      name: "Offensive Consistency",
      value: 0
    },
    DEFENSIVE_CONSISTENCY: {
      name: "Defensive Consistency",
      value: 0
    },
    INTANGIBLES: {
      name: "Intangibles",
      value: 0
    }
  },
  Conditioning: {
    STAMINA: {
      name: "Stamina",
      value: 0
    }
  }
};

export const badges = {
  Finishing: {
    ACROBAT: {
      name: "Layup Mixmaster",
      desc:
        "Increases the ability to finish an alley-oop from a teammate, or putback a finish off an offensive rebound",
      value: 0
    },
    BACKDOWN_PUNISHER: {
      name: "Post Powerhouse",
      desc:
        "Strengthens a player's ability at backing down defenders and moving them with dropsteps.",
      value: 0
    },
    CONSISTENT_FINISHER: {
      name: "Physical Finisher",
      desc:
        "Improves a player's ability to battle through contact and convert contact layups.",
      value: 0
    },
    CONTACT_FINISHER: {
      name: "Posterizer",
      desc: "Increases the chances of throwing down a dunk on your defender",
      value: 0
    },
    // "CROSS-KEY_SCORER": {
    //   name: "Cross-Key Scorer",
    //   desc:
    //     "Boosts the ability to make running hooks, layups, or close range pull-ups while driving across the paint.",
    //   value: 0
    // },
    DEEP_HOOKS: {
      name: "Hook Specialist",
      desc: "Improves a player's ability to make post hooks",
      value: 0
    },
    "DROP-STEPPER": {
      name: "Post Up Poet",
      desc:
        "Raises the chances of faking or getting by the defender, as well as scoring, when performing moves in the post.",
      value: 0
    },
    // FANCY_FOOTWORK: {
    //   name: "Fancy Footwork",
    //   desc:
    //     " players get past defenders more efficiently when performing euro, cradle, hop step, spin, and half-spin gathers.",
    //   value: 0
    // },
    // FASTBREAK_FINISHER: {
    //   name: "Fastbreak Finisher",
    //   desc:
    //     "Gives an additional boost to a player’s takeover meter when successfully dunking on a fastbreak.",
    //   value: 0
    // },
    LOB_CITY_FINISHER: {
      name: "Aerial Wizard",
      desc:
        "Increases the ability to finish an alley-oop from a teammate, or putback a finish off an offensive rebound",
      value: 0
    },
    // PICK_ROLLER: {
    //   name: "Pick and Roller",
    //   desc:
    //     "When rolling off the pick and roll, a shot boost is applied if the layup or dunk attempt comes within a few seconds after catching the pass.",
    //   value: 0
    // },
    // PRO_TOUCH: {
    //   name: "Pro Touch",
    //   desc:
    //     "Gives an extra shot boost for having slightly early, slightly late, or excellent shot timing on layups.",
    //   value: 0
    // },
    // POST_SPIN_TECHNICIAN: {
    //   name: "Post Up Poet",
    //   desc:
    //     "Raises the chances of faking or getting by the defender, as well as scoring, when performing moves in the post.",
    //   value: 0
    // },
    PUTBACK_BOSS: {
      name: "Aerial Wizard",
      desc:
        "Increases the ability to finish an alley-oop from a teammate, or putback a finish off an offensive rebound",
      value: 0
    },
    // RELENTLESS_FINISHER: {
    //   name: "Relentless Finisher",
    //   desc:
    //     "Improves a player’s ability to take a lot of contact by reducing the energy lost when attacking the rim for contact shots.",
    //   value: 0
    // },
    // SHOWTIME: {
    //   name: "Showtime",
    //   desc:
    //     "Gives an additional boost to a player’s takeover meter and his teammates, when successfully completing an and-1 or flashy dunk.",
    //   value: 0
    // },
    // SLITHERY_FINISHER: {
    //   name: "Slithery Finisher",
    //   desc:
    //     "Increases a player’s ability to slide through traffic and avoid contact during gathers and finishes at the rim.",
    //   value: 0
    // },
    TEAR_DROPPER: {
      name: "Float Game",
      desc: "Improves a player's ability to make floaters",
      value: 0
    }
  },
  Shooting: {
    CATCH_SHOOT: {
      name: "Set Shot Specialist",
      desc: "Specialist	Boosts chances of knocking down stand-still jump shots.",
      value: 0
    },
    // CLUTCH_SHOOTER: {
    //   name: "Clutch Shooter",
    //   desc:
    //     "Shot attempts that occur during the final moments of the 4th quarter, or in any overtime period, receive a large boost.",
    //   value: 0
    // },
    // CORNER_SPECIALIST: {
    //   name: "Corner Specialist",
    //   desc:
    //     "Deep mid-range or 3pt shots taken along the baseline of the court receive a boost, whether it is off the dribble or off the catch.",
    //   value: 0
    // },
    DEADEYE: {
      name: "Deadeye",
      desc:
        "Jump shots taken with a defender closing out receive less of a penalty from a shot contest. This includes both mid-range and 3pt shots.",
      value: 0
    },
    DEEP_FADES: {
      name: "Post Fade Phenom",
      desc: "Improves a player's ability to make post fades and hop shots",
      value: 0
    },
    DIFFICULT_SHOTS: {
      name: "Shifty Shooter",
      desc:
        "Improves a player's ability to successfully make off-the-dribble, high-difficulty jump shots.",
      value: 0
    },
    // FLEXIBLE_RELEASE: {
    //   name: "Flexible Release",
    //   desc:
    //     "Shot timing penalties for jump shots are reduced, making it easier to knock down attempts even when releasing early or late.",
    //   value: 0
    // },
    // GREEN_MACHINE: {
    //   name: "Green Machine",
    //   desc:
    //     "Gives an additional shot boost when consecutively achieving excellent releases on jump shots. ",
    //   value: 0
    // },
    // HOT_START: {
    //   name: "Hot Start",
    //   desc:
    //     "For every made shot from the beginning of the game, players receives a shot attribute bonus that lasts until the first missed shot attempt",
    //   value: 0
    // },
    // HOT_ZONE_HUNTER: {
    //   name: "Hot Zone Hunter",
    //   desc: "Shots that are taken in a player’s hot zone(s) are given a boost",
    //   value: 0
    // },
    // ICE_IN_VEINS: {
    //   name: "Ice in Veins",
    //   desc:
    //     "Free throws taken in the second half of close games or overtime periods are given a boost. Also, the timing window for free throws becomes larger.",
    //   value: 0
    // },
    GIANT_SLAYER: {
      name: "Mini Marksman",
      desc: "Elevates the likelihood of making shots over taller defenders.",
      value: 0
    },
    // PICK_POPPER: {
    //   name: "Pick & Popper",
    //   desc:
    //     "Shot attempts that come after setting a screen are given a boost if the shot happens far enough from the rim and within a few seconds after the screen has been set.",
    //   value: 0
    // },
    // PUMP_FAKE_MAESTRO: {
    //   name: "Pump Fake Maestro",
    //   desc:
    //     "Shortens the timer that determines how long after a pump fake a player can shoot without incurring a shot percentage penalty.",
    //   value: 0
    // },
    // QUICK_DRAW: {
    //   name: "Quick Draw",
    //   desc:
    //     "The higher the badge level, the faster a player will be able to release all jump shots.",
    //   value: 0
    // },
    RANGE_EXTENDER: {
      name: "Limitless Range",
      desc:
        "Extends the range from which a player can shoot three-pointers effectively from deep",
      value: 0
    },
    "SLIPPERY_OFF-BALL": {
      name: "Slippery Off-Ball",
      desc:
        "When attempting to get open off screens, the player more effectively navigates through traffic",
      value: 0
    }
    // STEADY_SHOOTER: {
    //   name: "Steady Shooter",
    //   desc:
    //     "Shot attempts that are contested receive less of a penalty, however shot attempts that are open do not receive as much of a bonus.",
    //   value: 0
    // },
    // TIRELESS_SCORER: {
    //   name: "Tireless Shooter",
    //   desc:
    //     "Shot attributes on jump shots suffer a smaller penalty than normal when tired.",
    //   value: 0
    // },
    // VOLUME_SHOOTER: {
    //   name: "Volume Shooter",
    //   desc:
    //     "After a player has taken a small handful of shots, an additional boost to shot attributes is given for ever subsequent shot, whether it’s a make or a miss.",
    //   value: 0
    // }
  },
  Playmaking: {
    ANKLE_BREAKER: {
      name: "Ankle Assassin",
      desc:
        "Improves the likelihood of freezing or dropping a defender during dribble moves, especially stepback moves or certain chains of dribble moves.",
      value: 0
    },
    BAIL_OUT: {
      name: "Bail Out",
      desc:
        "Increases the chances of a successful and accurate pass out of a jumpshot or layup while mid-air.",
      value: 0
    },
    BREAK_STARTER: {
      name: "Break Starter",
      desc:
        "Allows rebounders to throw more effective deep outlet passes shortly following a defensive rebound.",
      value: 0
    },
    DIMER: {
      name: "Dimer",
      desc:
        "Gives a shooting boost to receivers in catch-and-shoot oppurtunities.",
      value: 0
    },
    // DOWNHILL: {
    //   name: "Downhill",
    //   desc:
    //     " Increases your player’s speed with ball rating on fastbreak opportunities",
    //   value: 0
    // },
    // DREAM_SHAKE: {
    //   name: "Dream Shake",
    //   desc:
    //     "Increases the chances that a defender falls for a pump fake in the post. In addition, your player’s shooting attributes increase after post moves or pump fakes.",
    //   value: 0
    // },
    // FLASHY_PASSER: {
    //   name: "Flashy Passer",
    //   desc:
    //     "Gives a Takeover boost to the passer and receiver after following a made shot off a flashy pass.",
    //   value: 0
    // },
    // FLOOR_GENERAL: {
    //   name: "Floor General",
    //   desc: "Boosts your teammates’ offensive attributes when on the floor.",
    //   value: 0
    // },
    HANDLES_FOR_DAYS: {
      name: "Handles For Days",
      desc:
        "Allows playmakers and dribbling builds to lose less stamina when chaining dribble moves.",
      value: 0
    },
    // LOB_CITY_PASSER: {
    //   name: "Lob City Passer",
    //   desc:
    //     "Increases the chances of a successful alley-oop pass and finish. It boosts both your player’s passing attribute and the finishing attributes of your receiver.",
    //   value: 0
    // },
    NEEDLE_THREADER: {
      name: "Versatile Visionary",
      desc:
        "Improves a player's ability to thread and fit tight passes, including alley-oops, quickly and on time.",
      value: 0
    },
    // PASS_FAKE_MAESTRO: {
    //   name: "Pass Fake Maestro",
    //   desc:
    //     "Increases the effectiveness of fake passes by making them quicker and tighter.",
    //   value: 0
    // },
    QUICK_FIRST_STEP: {
      name: "Lightning Launch",
      desc: "Speeds up launches when attacking from the perimeter.",
      value: 0
    },
    // SPACE_CREATOR: {
    //   name: "Space Creator",
    //   desc:
    //     "Boosts your player’s ability to create space from a defender on a step back move or shot.",
    //   value: 0
    // },
    // STOP_GO: {
    //   name: "Stop & Go",
    //   desc:
    //     "Allows ball handlers to quickly stop-and-go while dribbling. You also get unique launch animations on stop-and-gos with this badge.",
    //   value: 0
    // },
    TIGHT_HANDLES: {
      name: "Strong Handle",
      desc:
        "Reduces the likelihood of being bothered by defenders when dribbling.",
      value: 0
    },
    UNPLUCKABLE: {
      name: "Unpluckable",
      desc:
        "Makes it more difficult for defenders to steal the ball from your player.",
      value: 0
    }
  },
  "Defense/Rebounding": {
    BOX: {
      name: "Boxout Beast",
      desc:
        "Improves a player's ability to box out and fight for good rebounding position",
      value: 0
    },
    BRICK_WALL: {
      name: "Brick Wall",
      desc:
        "This badge makes it tougher for a defense to get through or around screens. Players hit by contact from a brick wall lose more energy than normal.",
      value: 0
    },
    CHASE_DOWN_ARTIST: {
      name: "High Flying Denier",
      desc:
        "Boosts the speed and leaping ability of a player when he is chasing down an offensive player in anticipation of a block attempt.",
      value: 0
    },
    CLAMPS: {
      name: "Challenger",
      desc:
        "Improves the effectiveness of well-timed contests against perimeter shooters",
      value: 0
    },
    // DEFENSIVE_STOPPER: {
    //   name: "Defensive Leader",
    //   desc:
    //     "Lifts the defensive ability of teammates when on the court. Also, at the Hall of Fame level, can see potential shot percentages of opposing players.",
    //   value: 0
    // },
    // HEART_CRUSHER: {
    //   name: "Heart Crusher",
    //   desc:
    //     "After successfully blocking or stealing the ball from an opponent, an additional penalty is given to the opposing player’s takeover meter.",
    //   value: 0
    // },
    INTERCEPTOR: {
      name: "Interceptor",
      desc:
        "The frequency of successfully tipped or intercepted passes greatly increases.",
      value: 0
    },
    INTIMIDATOR: {
      name: "Immovable Enforcer",
      desc:
        "Improves a defensive player's strength when defending ball handlers and finishers",
      value: 0
    },
    // LIGHTNING_REFLEXES: {
    //   name: "Lightning Reflexes",
    //   desc:
    //     "Gives the defender an advantage to read where the ball handler is going in the Read and React System.",
    //   value: 0
    // },
    // MOVING_TRUCK: {
    //   name: "Moving Truck",
    //   desc:
    //     "Players are more effective pushing opponents out of the post while playing defense.",
    //   value: 0
    // },
    "OFF-BALL_PEST": {
      name: "Off-Ball Pest",
      desc:
        "Makes players more difficult to get past when playing off-ball, as they can grab and hold their matchup and don’t get their ankles broken as often.",
      value: 0
    },
    PICK_DODGER: {
      name: "Pick Dodger",
      desc:
        "Improves a player’s ability to navigate through and round screens while on defense.",
      value: 0
    },
    PICK_POCKET: {
      name: "Glove",
      desc:
        "	Increases the ability to successfully steal from ball-handlers, or strip layup attempts",
      value: 0
    },
    POGO_STICK: {
      name: "Pogo Stick",
      desc:
        "Allows players to quickly go back for another block attempt upon landing.",
      value: 0
    },
    POST_MOVE_LOCKDOWN: {
      name: "Post Lockdown",
      desc:
        "Strengthens a player's ability to effectively defend moves in the post, with an increased chance at stripping the opponent",
      value: 0
    },
    REBOUND_CHASER: {
      name: "Rebound Chaser",
      desc:
        "Improves a player’s ability to track down rebounds from farther distances than normal.",
      value: 0
    },
    RIM_PROTECTOR: {
      name: "Paint Patroller",
      desc:
        "Increases a player's ability to block or contest shots at the rim.",
      value: 0
    },
    // TIRELESS_DEFENDER: {
    //   name: "Tireless Defender",
    //   desc:
    //     "Allows defenders to play defense more aggressively without losing energy at the same rate as a normal player.",
    //   value: 0
    // },
    TRAPPER: {
      name: "On-Ball Menace",
      desc: "Hounds and bodies up while defending on the perimeter.",
      value: 0
    }
    // WORM: {
    //   name: "Worm",
    //   desc:
    //     "When boxed out, rebounders have more success swimming around and getting into successful rebound position.",
    //   value: 0
    // }
  },
  Personality: {
    ALPHA_DOG: {
      name: "Alpha Dog",
      desc: "Receive a stat boost when teammates are cold.",
      value: 0
    },
    ENFORCER: {
      name: "Enforcer",
      desc: "Gets away with harder fouls and occasionally flagrants.",
      value: 0
    },
    EXTREMELY_CONFIDENT: {
      name: "Extremely Condifent",
      desc: "Believes in his own success more than others, no afraid to shoot.",
      value: 0
    },
    TEAM_PLAYER: {
      name: "Team Player",
      desc:
        "Believes in team success, passes to the open man more often than now.",
      value: 0
    },
    UNPREDICTABLE: {
      name: "Unpredictable",
      desc: "Has erratic behaviour on the court",
      value: 0
    }
  }
};

export const hotzones = {
  "3_LEFT-CENTER": {
    x: "15%",
    y: "85%",
    value: 0
  },
  "3_RIGHT-CENTER": {
    x: "85%",
    y: "85%",
    value: 0
  },
  CENTER_3: {
    x: "50%",
    y: "85%",
    value: 0
  },
  CLOSE_LEFT: {
    x: "32%",
    y: "20%",
    value: 0
  },
  CLOSE_MIDDLE: {
    x: "50%",
    y: "35%",
    value: 0
  },
  CLOSE_RIGHT: {
    x: "68%",
    y: "20%",
    value: 0
  },
  LEFT_3: {
    x: "3%",
    y: "20%",
    value: 0
  },
  "MID-RANGE_LEFT": {
    x: "15%",
    y: "20%",
    value: 0
  },
  "MID-RANGE_LEFT_CENTER": {
    x: "28%",
    y: "55%",
    value: 0
  },
  "MID-RANGE_RIGHT": {
    x: "85%",
    y: "20%",
    value: 0
  },
  "MID-RANGE_RIGHT_CENTER": {
    x: "72%",
    y: "55%",
    value: 0
  },
  MID_CENTER: {
    x: "50%",
    y: "60%",
    value: 0
  },
  RIGHT_3: {
    x: "97%",
    y: "20%",
    value: 0
  },
  UNDER_BASKET: {
    x: "50%",
    y: "10%",
    value: 0
  }
};

// TZONE:
// 3_LEFT-CENTER: "0"
// 3_RIGHT-CENTER: "1"
// CENTER_3: "2"
// CLOSE_LEFT: "2"
// CLOSE_MIDDLE: "1"
// CLOSE_RIGHT: "1"
// LEFT_3: "1"
// MID-RANGE_LEFT: "0"
// MID-RANGE_LEFT_CENTER: "2"
// MID-RANGE_RIGHT: "1"
// MID-RANGE_RIGHT_CENTER: "1"
// MID_CENTER: "0"
// RIGHT_3: "0"
// UNDER_BASKET: "0"

// ACROBAT: "0"
// ALPHA_DOG: "1"
// ANKLE_BREAKER: "1"
// BACKDOWN_PUNISHER: "2"
// BAIL_OUT: "0"
// BOX: "1"
// BREAK_STARTER: "0"
// BRICK_WALL: "0"
// CAREER_GYM_ELIMINATOR: "0"
// CATCH_SHOOT: "0"
// CHASE_DOWN_ARTIST: "1"
// CLAMPS: "0"
// CLUTCH_SHOOTER: "1"
// CONSISTENT_FINISHER: "4"
// CONTACT_FINISHER: "1"
// CORNER_SPECIALIST: "0"
// CROSS-KEY_SCORER: "0"
// DEADEYE: "1"
// DEEP_FADES: "0"
// DEEP_HOOKS: "0"
// DEFENSIVE_STOPPER: "0"
// DIFFICULT_SHOTS: "1"
// DIMER: "0"
// DOWNHILL: "0"
// DREAM_SHAKE: "0"
// DROP-STEPPER: "0"
// ENFORCER: "0"
// EXPRESSIVE: "0"
// EXTREMELY_CONFIDENT: "0"
// FANCY_FOOTWORK: "2"
// FASTBREAK_FINISHER: "1"
// FINANCE_SAVVY: "0"
// FLASHY_PASSER: "0"
// FLEXIBLE_RELEASE: "0"
// FLOOR_GENERAL: "0"
// FRIENDLY: "0"
// GIANT_SLAYER: "0"
// GREEN_MACHINE: "4"
// HANDLES_FOR_DAYS: "1"
// HEART_CRUSHER: "0"
// HIGH_WORK_ETHIC: "0"
// HOT_START: "2"
// HOT_ZONE_HUNTER: "4"
// ICE_IN_VEINS: "0"
// INTERCEPTOR: "0"
// INTIMIDATOR: "0"
// KEEP_IT_REAL: "0"
// LAID_BACK: "0"
// LEGENDARY_WORK_ETHIC: "0"
// LIGHTNING_REFLEXES: "0"
// LOB_CITY_FINISHER: "3"
// LOB_CITY_PASSER: "0"
// MEDIA_RINGMASTER: "0"
// MOVING_TRUCK: "0"
// NEEDLE_THREADER: "1"
// OFF-BALL_PEST: "0"
// ON_COURT_COACH: "0"
// PASS_FAKE_MAESTRO: "0"
// PAT_MY_BACK: "0"
// PICK_DODGER: "0"
// PICK_POCKET: "0"
// PICK_POPPER: "2"
// PICK_ROLLER: "0"
// POGO_STICK: "2"
// POST_MOVE_LOCKDOWN: "0"
// POST_SPIN_TECHNICIAN: "0"
// PRO_TOUCH: "0"
// PUMP_FAKE_MAESTRO: "0"
// PUTBACK_BOSS: "0"
// QUICK_DRAW: "0"
// QUICK_FIRST_STEP: "2"
// RANGE_EXTENDER: "0"
// REBOUND_CHASER: "0"
// RELENTLESS_FINISHER: "4"
// RESERVED: "0"
// RIM_PROTECTOR: "0"
// SHOWTIME: "0"
// SLIPPERY_OFF-BALL: "0"
// SLITHERY_FINISHER: "1"
// SPACE_CREATOR: "0"
// STEADY_SHOOTER: "2"
// STOP_GO: "3"
// TEAM_PLAYER: "0"
// TEAR_DROPPER: "0"
// TIGHT_HANDLES: "2"
// TIRELESS_DEFENDER: "0"
// TIRELESS_SCORER: "0"
// TRAPPER: "0"
// UNPLUCKABLE: "0"
// UNPREDICTABLE: "0"
// VOLUME_SHOOTER: "3"
// WARM_WEATHER_FAN: "0"
// WORM: "1"

// 3PT_SHOT: "198"
// ACCELERATION: "168"
// BACK_DURABILITY: "171"
// BALL_CONTROL: "190"
// BLOCK: "78"
// DEFENSIVE_CONSISTENCY: "192"
// DEFENSIVE_REBOUND: "190"
// DRAW_FOUL: "99"
// DRIVING_DUNK: "81"
// DRIVING_LAYUP: "0"
// EMOTION_ABILITY: "115"
// FREE_THROW: "219"
// HANDS: "204"
// HEAD_DURABILITY: "132"
// HELP_DEFENSIVE_IQ: "165"
// HUSTLE: "164"
// INTANGIBLES: "144"
// INTERIOR_DEFENSE: "126"
// LATERAL_QUICKNESS: "73"
// LEFT_ANKLE_DURABILITY: "210"
// LEFT_ELBOW_DURABILITY: "156"
// LEFT_FOOT_DURABILITY: "189"
// LEFT_HIP_DURABILITY: "141"
// LEFT_KNEE_DURABILITY: "177"
// LEFT_SHOULDER_DURABILITY: "195"
// MID-RANGE_SHOT: "141"
// MISC_DURABILITY: "180"
// NECK_DURABILITY: "153"
// OFFENSIVE_CONSISTENCY: "114"
// OFFENSIVE_REBOUND: "134"
// ON-BALL_DEFENSE_IQ: "172"
// PASSING_ACCURACY: "203"
// PASSING_IQ: "195"
// PASSING_VISION: "117"
// PASS_PERCEPTION: "50"
// PERIMETER_DEFENSE: "147"
// PICK_AND_ROLL_DEFENSIVE_IQ: "79"
// POST_FADEAWAY: "147"
// POST_HOOK: "160"
// POST_MOVES: "163"
// POTENTIAL: "168"
// REACTION_TIME: "210"
// RIGHT_ANKLE_DURABILITY: "168"
// RIGHT_ELBOW_DURABILITY: "189"
// RIGHT_FOOT_DURABILITY: "207"
// RIGHT_HIP_DURABILITY: "171"
// RIGHT_KNEE_DURABILITY: "150"
// RIGHT_SHOULDER_DURABILITY: "156"
// SHOT_CLOSE: "175"
// SHOT_CONTEST: "62"
// SHOT_IQ: "186"
// SPEED: "195"
// SPEED_WITH_BALL: "138"
// STAMINA: "194"
// STANDING_DUNK: "135"
// STEAL: "105"
// STRENGTH: "89"
// VERTICAL: "83"

export const playerDetails = {
  Name: "",
  XOverall: "",
  Overall: "???",
  Position: "",
  Height: "???",
  Weight: "???",
  Team: "FA",
  Zeke: "",
  Devin: "",
  Will: "",
  Age: "???",
  "Contract Length": "1",
  "NBA comparison": "",
  Rank: {},
  Status: "HEALTHY",
  Salary: "0",
  Image: "",
  "Retiring?": false,
  Updates: [],
  "TriKov Value": 1,
  Role: 14,
  Loyalty: "???",
  botValue1: 0,
  botValue2: 0,
  botValue3: 0,
  "Shades Of": "None",
  "Prior Team": "None",
  "Contract Offer": {
    Minutes: 0,
    Cash: 0,
    Loyalty: 0,
    Extendable: false,
    Supermax: false
  },
  "signed?": false,
  Tendency: "None",
  "Old Tendency Values": "{}",
  "Other Team": "",
  Extendable: false,
  Changes: {}
};
